import React, { useState } from "react";
import styles from "./DownloadDataButton.module.css";
import { projectMap as currentProjectMap } from "./ProjectList";
import Button from "./Button";
const XLSX = require("xlsx");

export let isTableButtonVisible, setIsTableButtonVisible;

async function downloadInfo() {
  const errorMessage = document.getElementById("error-message");
  if (currentProjectMap.length === 0) {
    errorMessage.textContent = "Error: Request Data required";
    errorMessage.style.color = "red";
    return;
  }
  errorMessage.textContent = "";
  let rows = [];
  const headers = [
    "projectName",
    "destination(waterfall api)",
    "frequency",
    "source?",
    "id",
  ];
  const headersTag = ["tagName", "minValue", "maxValue", "type", ""];
  const projectEmpty = ["", "", "", "", ""];
  currentProjectMap.forEach((project) => {
    const projectData = [
      project.ProjectName,
      project.Destination,
      project.Frequency,
      "3rd party ID",
      project.ProjectId,
    ];
    rows.push(headers);
    rows.push(projectData);
    rows.push(projectEmpty);
    rows.push(headersTag);
    project.tags.forEach(async (tag) => {
      const tagData = [
        tag.TagName,
        tag.MinValue,
        tag.MaxValue,
        tag.DataType,
        tag.TagId,
      ];
      rows.push(tagData);
    });
  });
  const projectNameValue = rows[1][0];
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, projectNameValue + ".xlsx");
}

const DownloadDataButton = () => {
  [isTableButtonVisible, setIsTableButtonVisible] = useState(false);
  const handleButtonDownload = (event) => {
    event.preventDefault();
    downloadInfo();
  };

  return (
    <>
      {isTableButtonVisible && (
        <>
          <div className={styles.myStyleBottom}>
            <Button
              type="primary"
              children="Download Data"
              onClick={handleButtonDownload}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DownloadDataButton;
