import React, {useState, useEffect} from 'react';
import { thStyles } from './ModalStyles';
import PropTypes from 'prop-types'
import styles from './ModalTagWindow.module.css';
import Button2 from '../Project/ProjectComponents/Button2';
import ErrorIcon from './ModalTagError';
import {
  isValidProjectName,
  isValidTagName,
  isValidnewPredictionType,
} from "../../utils/validationUtils";

export let newTagId, setNewTagId;
export let newTagName, setNewTagName;
export let tagName, settagName;
export let newPredictionType, setnewPredictionType;
export let newPredictionMin, setnewPredictionMin;
export let newPredictionMax, setnewPredictionMax;
export let factoryMatch;
export let projectNameMatch;
export let itemNameMatch;
export let updateFactoryNameInTags;
export let updateProjectNameInTags;

const TagsComponent = ({
  tags,
  setTags,
  projectName,
  factoryName,
  removedTags,
  setRemovedTags,
}) => {
  [newTagId, setNewTagId] = useState("");
  [newTagName, setNewTagName] = useState("");
  [tagName, settagName] = useState("");
  [newPredictionType, setnewPredictionType] = useState("");
  [newPredictionMin, setnewPredictionMin] = useState("");
  [newPredictionMax, setnewPredictionMax] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  let errors = {};

  const validateForm = () => {
    const validProjectName = isValidProjectName(projectName);
    const validTagName = isValidTagName(tagName);
    const validnewPredictionType = isValidnewPredictionType(newPredictionType);

    //   const isCamelCase = /^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/.test(projectName);
    //   const isValidFactoryName = /^[A-Z]\d*$/.test(factoryName);
    //   const isValidTagName = /^[a-zA-Z0-9]+[a-zA-Z0-9_]*(?<!_)$/.test(tagName);
    //   const isValidnewPredictionType = /^(int|float|boolean)$/.test(newPredictionType);
    const isValidnewPredictionMin =
      !isNaN(newPredictionMin) && newPredictionMin.trim() !== "";
    const isValidnewPredictionMax =
      !isNaN(newPredictionMax) &&
      newPredictionMax.trim() !== "" &&
      parseFloat(newPredictionMax) >= parseFloat(newPredictionMin);

    factoryMatch = tags.every((tag) => {
      const parts = tag.TagName.split(".");
      return parts[2] === factoryName;
    });
    projectNameMatch = tags.every((tag) => {
      const parts = tag.TagName.split(".");
      return parts[3] === projectName;
    });
    itemNameMatch = tags.every((tag) => {
      const parts = tag.TagName.split(".");
      return parts[4] !== tagName;
    });
    if (!projectName.trim()) errors.projectName = "Project Name is required.";
    else if (!validProjectName.isValid)
      errors.projectName = validProjectName.message;
    // if (!projectNameMatch) errors.projectName = "Project Name must match the Project Name of the Factory.";
    if (!factoryName.trim()) errors.factoryName = "Plant ID Name is required.";
    if (!factoryMatch)
      errors.factoryName = "Plant ID must match the Plant ID of the Factory.";
    if (!tagName.trim()) errors.tagName = "Tag Name is required.";
    else if (!validTagName.isValid) errors.tagName = validTagName.message;
    if (!itemNameMatch)
      errors.tagName =
        "Tag Name must not match any other Tag Names in the Project.";
    if (!newPredictionType.trim())
      errors.newPredictionType = "Prediction Type is required.";
    else if (!validnewPredictionType.isValid)
      errors.newPredictionType = validnewPredictionType.isValid;
    if (!newPredictionMin.trim())
      errors.newPredictionMin = "Prediction Minimum Value is required.";
    else if (!isValidnewPredictionMin)
      errors.newPredictionMin = "Prediction Minimum Value must be a number.";
    if (!newPredictionMax.trim())
      errors.newPredictionMax = "Prediction Maximum Value is required.";
    else if (!isValidnewPredictionMax)
      errors.newPredictionMax =
        "Prediction Maximum Value must be a number greater than or equal to Minimum Value.";

    setErrorMessages(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [
    projectName,
    factoryName,
    tagName,
    newPredictionType,
    newPredictionMin,
    newPredictionMax,
  ]);

  const handleAddTag = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      validateForm();
      return;
    }
    if (
      projectName.trim() !== "" &&
      factoryName.trim() !== "" &&
      tagName.trim() !== "" &&
      newPredictionType.trim() !== "" &&
      newPredictionMin.trim() !== "" &&
      newPredictionMax.trim() !== ""
    ) {
      if (isFormValid) {
        newTagName =
          "CLOUD.PLC." + factoryName + "." + projectName + "." + tagName;
        const newTag = {
          TagName: newTagName,
          DataType: newPredictionType,
          MinValue: newPredictionMin,
          MaxValue: newPredictionMax,
        };
        setTags([...tags, newTag]);
        setNewTagName("");
        settagName("");
        setnewPredictionType("");
        setnewPredictionMin("");
        setnewPredictionMax("");
      }
    }
  };

  const handleRemoveTag = (tag, e) => {
    e.preventDefault();
    const newTags = [...tags];
    const nameIndex = newTags.findIndex((t) => t.TagName === tag.TagName);
    if (nameIndex !== -1) {
      const removedTag = newTags.splice(nameIndex, 1)[0];
      setTags(newTags);
      // const formattedRemovedTag = `${projectName}_${removedTag.TagId.slice(0, 6)}`;
      const formattedRemovedTag = removedTag.TagId;
      setRemovedTags([...removedTags, formattedRemovedTag]);
    }
  };

  const handleDataTypeChange = (id, newType) => {
    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.TagId === id ? { ...tag, DataType: newType } : tag
      )
    );
  };

  const handleMinValueChange = (id, newValue) => {
    const isNumeric = /^-?\d+$/.test(newValue);
    if (!isNumeric && !(newValue.trim() === "") && !(newValue.trim() === "-")) {
      return;
    }
    setTags((prevTags) =>
      prevTags.map((tag) => {
        if (tag.TagId === id) {
          const newMinValue = parseFloat(newValue);
          const currentMaxValue = parseFloat(tag.MaxValue);
          if (
            newMinValue <= currentMaxValue ||
            newValue.trim() === "" ||
            newValue.trim() === "-"
          ) {
            return { ...tag, MinValue: newValue };
          } else {
            return tag;
          }
        }
        return tag;
      })
    );
  };

  const handleMaxValueChange = (id, newValue) => {
    setTags((prevTags) =>
      prevTags.map((tag) => {
        if (tag.TagId === id) {
          const newMaxValue = parseFloat(newValue);
          const currentMinValue = parseFloat(tag.MinValue);
          if (currentMinValue <= newMaxValue) {
            return { ...tag, MaxValue: newValue };
          } else {
            return { ...tag, MaxValue: tag.MinValue };
          }
        }
        return tag;
      })
    );
  };

  const handleInputBlur = (id, value) => {
    if (value.trim() === "" || value.trim() === "-") {
      handleMinValueChange(id, "0");
    }
  };

  return (
    <div>
      <table className={styles.tableStyles}>
        <tr>
          <th style={thStyles}>Name</th>
          <th style={thStyles}>Type</th>
          <th style={thStyles}>Minimum Value</th>
          <th style={thStyles}>Maximum Value</th>
        </tr>
        <br />
        {tags.map((tag) => (
          <tr key={tag.TagId}>
            <td>
              <input className={styles.tagB} value={tag.TagName} readOnly />
            </td>
            <td>
              {/* <input className={styles.tagB2} value={tag.DataType} /> */}
              <select
                className={styles.tag}
                value={tag.DataType}
                onChange={(e) =>
                  handleDataTypeChange(tag.TagId, e.target.value)
                }
              >
                <option value="" disabled>
                  {" "}
                  Select Prediction Type{" "}
                </option>
                <option value="int">int</option>
                <option value="float">float</option>
                <option value="boolean">boolean</option>
              </select>
            </td>
            <td>
              <input
                className={styles.tag}
                value={tag.MinValue}
                onChange={(e) =>
                  handleMinValueChange(tag.TagId, e.target.value)
                }
                onBlur={(e) => handleInputBlur(tag.TagId, e.target.value)}
              />
            </td>
            <td>
              <input
                className={styles.tag}
                value={tag.MaxValue}
                onChange={(e) =>
                  handleMaxValueChange(tag.TagId, e.target.value)
                }
              />
            </td>
            <td>
              <Button2
                type="warning"
                children="-"
                onClick={(e) => handleRemoveTag(tag, e)}
              />
            </td>
          </tr>
        ))}
        <tr>
          <td className={styles.tagTextStarter}>
            <span className={styles.tagText}>CLOUD.PLC.</span>
            <input
              className={styles.tagFactoryName}
              type="text"
              value={factoryName}
              readOnly
            />
            <span className={styles.tagText}>.</span>
            <input
              className={styles.tagProjName}
              type="text"
              value={projectName}
              readOnly
            />
            <span className={styles.tagText}>.</span>
            <input
              className={styles.tagM}
              type="text"
              value={tagName}
              onChange={(e) => settagName(e.target.value)}
              placeholder="Tag Name"
            />
          </td>
          <td>
            <select
              className={styles.tag}
              value={newPredictionType}
              onChange={(e) => setnewPredictionType(e.target.value)}
              placeholder="Prediction Type"
            >
              <option value="" disabled>
                {" "}
                Select Prediction Type{" "}
              </option>
              <option value="int">int</option>
              <option value="float">float</option>
              <option value="boolean">boolean</option>
              {/* Add more options as needed */}
            </select>
          </td>
          <td>
            <input
              className={styles.tag}
              type="text"
              value={newPredictionMin}
              onChange={(e) => setnewPredictionMin(e.target.value)}
              placeholder="Prediction Min value"
            />
          </td>
          <td>
            <input
              className={styles.tag}
              type="text"
              value={newPredictionMax}
              onChange={(e) => setnewPredictionMax(e.target.value)}
              placeholder="Prediction Max value"
            />
          </td>
          <td>
            <form onSubmit={handleAddTag}>
              <Button2 type="submit" children="+" />
            </form>
          </td>
        </tr>
      </table>
      {/* <div className={styles.errorContainerStyles}> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: "92%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {Object.values(errorMessages).map((error, index) => (
          <ErrorIcon error={error} index={index} />
        ))}
      </div>
    </div>
  );
};

TagsComponent.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  newTagName: PropTypes.string,
  setNewTagName: PropTypes.func,
  newPredictionType: PropTypes.string,
  setnewPredictionType: PropTypes.func,
  newPredictionMin: PropTypes.string,
  setnewPredictionMin: PropTypes.func,
  newPredictionMax: PropTypes.string,
  setnewPredictionMax: PropTypes.func
};





export default TagsComponent;