import React, { useState } from 'react';
import styles from './Search.module.css';
import Button from './Button';
import { urlSearchProject } from '../../../Settings/url';
import { isValidProjectName } from "../../../utils/validationUtils";
import { setProjectMap, ProjectOpener, setIsTableVisible } from "./ProjectList";
import { setIsTableButtonVisible } from "./DownloadDataButton";
import { useMsal } from "@azure/msal-react";

async function getInfo(projectName, userToken) {
  console.log(`Project Name is: ${projectName}`);
  const errorMessage = document.getElementById("error-message");
  errorMessage.textContent = "";

  const validation = isValidProjectName(projectName);

  if (!validation.isValid) {
    errorMessage.style.color = validation.color;
    errorMessage.textContent = validation.message;
    return;
  }

  let jsonData = {
    projectName: projectName,
  };
  const response = await fetch(urlSearchProject, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify(jsonData),
  });
  const blob = await response.blob();
  if (!response.ok) {
    await new Promise((resolve, reject) => {
      const errorReader = new FileReader();
      errorReader.onload = function (event) {
        const data = event.target.result;
        reject(new Error(data));
      };
      errorReader.readAsText(blob);
    });
  }
  function displayData(data) {
    const jsonDataAll = JSON.parse(data);
    let projectMap = [];
    const errorMessage = document.getElementById("error-message");
    errorMessage.textContent = "";
    if (Array.isArray(jsonDataAll) && jsonDataAll.length === 0) {
      errorMessage.style.color = "red";
      errorMessage.textContent = "Project not found.";
      return;
    }
    jsonDataAll.forEach((jsonData, index) => {
      //   const projectName = jsonData.PredictionId.substring(0, jsonData.PredictionId.length - 7);
      const tags = {
        TagId: jsonData.PredictionId,
        TagName: jsonData.TagName,
        DataType: jsonData.DataType,
        MinValue: jsonData.MinValue,
        MaxValue: jsonData.MaxValue,
      };
      if (index === 0) {
        const projectJson = {
          ProjectId: jsonData.ProjectId,
          ProjectName: jsonData.ProjectName,
          Destination: jsonData.Destination,
          Frequency: jsonData.Frequency,
          tags: [tags],
          CreatedAt: jsonData.CreatedAt,
          CreatedBy: jsonData.CreatedBy,
          ModifiedAt: jsonData.ModifiedAt,
          ModifiedBy: jsonData.ModifiedBy,
          Status: jsonData.Status,
        };
        projectMap.push(projectJson);
      } else {
        projectMap[projectMap.length - 1].tags.push(tags);
      }
    });
    setProjectMap(projectMap);
    setIsTableVisible(true);
    setIsTableButtonVisible(true);
    ProjectOpener(projectMap[0].Status);
  }
  const reader = new FileReader();
  reader.onload = function (event) {
    const data = event.target.result;
    displayData(data);
  };
  reader.readAsText(blob);
}

const Search = () => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken;

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await getInfo(inputText, userToken);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.row}>
      <div className={styles.search}>
        <input
          id="textInput"
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Search Project Name..."
        />
      </div>
      <Button
        type={isLoading ? "secondary" : "primary"}
        children="Request Data"
        onClick={handleButtonClick}
        disabled={isLoading}
      />
      {isLoading ? <div className={styles.loader}></div> : ""}
    </div>
  );
};

export default Search;
