import React, { useState, useEffect } from 'react';
import Button from './Button';
import styles from './ProjectList.module.css';
import { urlUpdateStatus, urlUpdateProject } from '../../../Settings/url';
import { setProjectId, setProjectName, setFactoryName, setProjectDestination, setProjectFrequency, setTags } from '../../ModalWindows/ModalEdit';
import ModalEdit from '../../ModalWindows/ModalEdit';
import ModalShutdownConfirmation from '../../ModalWindows/ModalShutdownConfirmation';
import { setIsTableButtonVisible } from './DownloadDataButton';
import { useMsal } from '@azure/msal-react';

export let isTableVisible, setIsTableVisible
export let projectMap, setProjectMap
export let ProjectOpener

const ProjectList = () => {
  [isTableVisible, setIsTableVisible] = useState(false);
  [projectMap, setProjectMap] = useState([]);
  const [isProject, setProject] = useState(null);
  const [openProjectId, setOpenProjectId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("Active");
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalShutdownConfirmationOpen, setModalShutdownConfirmationOpen] =
    useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledEdit, setIsDisabledEdit] = useState(false);
  const [isDisabledShutdown, setIsDisabledShutdown] = useState(false);
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken;

  useEffect(() => {
    if (projectMap && projectMap[0]) {
      setCurrentStatus(projectMap[0].Status);
    }
  }, [projectMap]);

  useEffect(() => {
    if (currentStatus === "Shutdown") {
      setIsDisabledAll(true);
    } else if (currentStatus !== "Active") {
      setIsDisabledEdit(true);
      setIsDisabledShutdown(false);
      setIsDisabled(false);
    } else if (currentStatus === "Active") {
      setIsDisabledAll(false);
    }
  }, [currentStatus]);

  ProjectOpener = (status) => {
    setCurrentStatus(`${status}`);
    setOpenProjectId();
  };

  const handleProjectClick = (projectId) => {
    setOpenProjectId(projectId === openProjectId ? null : projectId);
  };

  function displayData(data) {
    setProjectMap([data]);
    setIsTableVisible(true);
    setIsTableButtonVisible(true);
    ProjectOpener();
  }
  const setIsDisabledAll = (value) => {
    setIsDisabled(value);
    setIsDisabledEdit(value);
    setIsDisabledShutdown(value);
  };

  const handleStatusToggle = async (project) => {
    setIsDisabledAll(true);
    if (project.Status === "Active") {
      const sendData = {
        ProjectName: project.ProjectName,
        TagIds: project.tags.map((tag) => tag.TagId),
        ChangeName: "Status",
        ChangeValue: "Pause",
      };
      const response = await fetch(urlUpdateStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(sendData),
      });
      if (response.ok) {
        project.Status = "Pause";
        setCurrentStatus("Pause");
      }
      setIsDisabledAll(false);
    } else {
      setIsDisabledAll(true);
      const sendData = {
        ProjectName: project.ProjectName,
        TagIds: project.tags.map((tag) => tag.TagId),
        ChangeName: "Status",
        ChangeValue: "Active",
      };
      const response = await fetch(urlUpdateStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(sendData),
      });
      if (response.ok) {
        project.Status = "Active";
        setCurrentStatus("Active");
      }
      setIsDisabledAll(false);
    }
  };

  /* Modal Edit ************************************************/
  const handleEditClick = (project, e) => {
    e.preventDefault();
    setProjectId(project.ProjectId);
    setProjectName(project.ProjectName);
    setProjectDestination(project.Destination);
    setProjectFrequency(project.Frequency);
    const factoryName = project.tags[0].TagName.split(".");
    setFactoryName(factoryName[2]);
    setTags(project.tags);
    // setNewTag('');
    setModalEditOpen(true);
  };

  const handleAcceptModalEdit = async (projectData) => {
    try {
      const response = await fetch(urlUpdateProject, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(projectData),
      });
      if (response.ok) {
        const newProject = await response.json();
        displayData(newProject);
      }
    } catch (err) {
      console.error("Error editing project:", err);
    }
    setModalEditOpen(false);
  };

  const closeModalEdit = (e) => {
    e.preventDefault();
    setModalEditOpen(false);
  };

  const confirmShutdown = (project) => {
    setProject(project);
    setModalShutdownConfirmationOpen(true);
  };

  const handleShutdown = async () => {
    setIsDisabledAll(true);
    const project = isProject;
    const sendData = {
      ProjectName: project.ProjectName,
      TagIds: project.tags.map((tag) => tag.TagId),
      ChangeName: "Status",
      ChangeValue: "Shutdown",
    };
    const response = await fetch(urlUpdateStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify(sendData),
    });
    if (response.ok) {
      project.Status = "Shutdown";
      setCurrentStatus("Shutdown");
    }
    setIsDisabledAll(false);
    setModalShutdownConfirmationOpen(false);
  };

  const closeModalShutdownConfirmation = (e) => {
    e.preventDefault();
    setModalShutdownConfirmationOpen(false);
  };
  /*******************************************************************/

  const tableHeader = () => {
    return (
      <React.Fragment key="headerFragment">
        <tr>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            ProjectID
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Name
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Destination
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Frequency
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Actions
          </th>
        </tr>
      </React.Fragment>
    );
  };

  const tableBody = () => {
    return projectMap.map((project) => (
      <React.Fragment key={project.Id}>
        <tr
          className={
            openProjectId === project.Id
              ? {
                  ...`${styles.tdStyles} ${
                    !(currentStatus === "Active") ? styles.tableDisabled : ""
                  }`,
                }
              : `${styles.tdStyles} ${
                  !(currentStatus === "Active") ? styles.tableDisabled : ""
                }`
          }
        >
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.ProjectId}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.ProjectName}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.Destination}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.Frequency}
          </td>
          <td
            className={`${styles.tdButtonStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            <Button
              type={isDisabledEdit ? "secondary" : "primary"}
              children="Edit"
              onClick={(e) => handleEditClick(project, e)}
              disabled={isDisabledEdit}
            />
            <Button
              type={isDisabled ? "secondary" : "primary"}
              children={currentStatus === "Active" ? "Pause" : "Re-Activate"}
              onClick={(e) => {
                handleStatusToggle(project);
              }}
              disabled={isDisabled}
            />
            <Button
              type={isDisabledShutdown ? "secondary" : "primary"}
              children="Shutdown"
              onClick={(e) => {
                confirmShutdown(project);
              }}
              disabled={isDisabledShutdown}
            />
          </td>
        </tr>
        {openProjectId === project.Id && (
          <tr>
            <td colSpan="5">
              <table style={{ minWidth: "100%" }}>
                <thead>
                  <tr>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Tag ID
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Tag Name
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      DataType
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Min Value
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Max Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {project.tags.map((tag, index) => (
                    <tr key={index}>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.TagId}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.TagName}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.DataType}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.MinValue}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.MaxValue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      {isTableVisible && (
        <>
          <table
            className={`${styles.tableStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            <thead>{tableHeader()}</thead>
            <tbody>{tableBody()}</tbody>
          </table>
          <div>
            <ModalEdit
              isOpen={isModalEditOpen}
              onClose={closeModalEdit}
              onAccept={handleAcceptModalEdit}
            />
            <ModalShutdownConfirmation
              isOpen={isModalShutdownConfirmationOpen}
              onClose={closeModalShutdownConfirmation}
              onAccept={handleShutdown}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectList;